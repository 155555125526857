import React from 'react'
import Helmet from 'react-helmet'
import Header from '../components/header'
import favicon from '../images/favicon.ico'
import logo from '../images/Logo.png'
import '../bootstrap/bootstrap.css'
import './index.css'

const Layout = ({ whiteLogo, children, className }) => (
  <div className={(className?"content "+className:"content")}>
    <Helmet
      title="Fairfest Media - India’s leading tradeshow organiser"
      meta={[
        {
          name: 'description',
          content: `Fairfest is a leading media company providing platforms for doing business in one of the fastest growing markets in the world - India. Since 1989, Fairfest organises industry leading tradeshows in the travel and city development space. Come, partner with Fairfest, and grow your business.`,
        },
        {
          name: 'keywords',
          content:
            'Fairfest,OTM, OTM India, TTF, TTF India, Fairfest Media Limited, BTM, Business Travel and Mice, Capex, ' +
            'Municipalika, TND, Travel News Digest, UND, Urban News Digest, India’s leading  travel trade show, Mumbai, Delhi, OTM Mumbai, TTF Delhi',
        },
        { name: 'og:image', content: logo },
      ]}
      link={[{ rel: 'shortcut icon', type: 'image/ico', href: `${favicon}` }]}
    />
    <Header renderWhite={whiteLogo} siteTitle="Fairfest.in" />
    {children}
  </div>
)

export default Layout
