import React, { Component } from 'react'
import { Link } from 'gatsby'
import FairfestLogo from '../../images/FairfestLogo.png'
import FairfestWhiteLogo from '../../images/FairfestWhiteLogo.png'
import Hamburger from '../../images/Hamburger.png'
import WhiteHamburger from '../../images/whiteHamburger.png'
import ExitButton from '../../images/Exit.png'
import './header.css'

class Header extends Component {
  state = { displayMenu: false }

  toggleMenu = () =>
    this.setState(({ displayMenu }) => ({ displayMenu: !displayMenu }))

  render() {
    const { renderWhite } = this.props
    const { displayMenu } = this.state

    return (
      <section id="header">
        <div className="logo-box col-sm-3">
          <Link to="/">
            {renderWhite ? (
              <img className="logo-img" src={FairfestWhiteLogo} alt="logo" />
            ) : (
              <img className="logo-img" src={FairfestLogo} alt="logo" />
            )}
          </Link>
        </div>
        <div className="logo-boxdesktop col-sm-9">
          <ul class="navbar-navdesktop">
            <li class="nav-item">
              <Link
                to="/travel-and-tourism"
                onClick={this.toggleMenu}
                className={`nav-link ${
                  renderWhite ? 'white' : 'gray'
                } hum-tit1`}
              >
                TRAVEL & TOURISM
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/urban-development"
                onClick={this.toggleMenu}
                className={`nav-link ${
                  renderWhite ? 'white' : 'gray'
                } hum-tit2`}
              >
                URBAN DEVELOPMENT
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/technology"
                onClick={this.toggleMenu}
                className={`nav-link ${
                  renderWhite ? 'white' : 'gray'
                } hum-tit3`}
              >
                TECHNOLOGY
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/our-team"
                onClick={this.toggleMenu}
                className={`nav-link ${renderWhite ? 'white' : 'gray'}`}
              >
                OUR TEAM
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/contact-us"
                onClick={this.toggleMenu}
                className={`nav-link ${renderWhite ? 'white' : 'gray'}`}
              >
                CONTACT US
              </Link>
            </li>
          </ul>
        </div>

        {/* <nav class="navbar navbar-expand-md bg-dark navbar-dark">
  <a class="navbar-brand" href="#">Navbar</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>    
    </ul>
  </div>  
</nav> */}

        {/* <ul className="navbar-nav mr-auto mt-2 mt-lg-0 over-txt">
                    <li className="nav-item">
                      <Link to="/travel-and-tourism"
                        onClick={this.toggleMenu}
                        className="nav-link hum-tit1">
                        TRAVEL & TOURISM
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/urban-development"
                        onClick={this.toggleMenu}
                        className="nav-link hum-tit2">
                        URBAN DEVELOPMENT
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/technology"
                        onClick={this.toggleMenu}
                        className="nav-link hum-tit3"
                      >
                        TECHNOLOGY 
                      </Link>
                    </li>
                    <li className="nav-item">
                    <Link
                      to="/leadership"
                      onClick={this.toggleMenu}
                      className="nav-link hum-bottom"
                    >
                      PRofiel 
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/contact-us"
                      onClick={this.toggleMenu}
                      className="nav-link hum-bottom"
                    >
                      CONTACT US
                    </Link>
                  </li>
                </ul> */}

        <div className="Hamburger col-sm-9">
          <span
            onClick={this.toggleMenu}
            style={{ cursor: 'pointer', float: 'right' }}
          >
            {renderWhite ? (
              <img className="logo-menu" src={WhiteHamburger} alt="menu" />
            ) : (
              <img className="logo-menu" src={Hamburger} alt="menu" />
            )}
          </span>
          <div
            id="myNav"
            className="overlay"
            style={displayMenu ? { width: '100%' } : { width: 0 }}
          >
            <div className="col-sm-6 inner-white-logo">
              <Link to="/" onClick={this.toggleMenu}>
                <img
                  className="white-logo"
                  src={FairfestWhiteLogo}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="col-sm-6 inner-white-humberger">
              <button
                onClick={this.toggleMenu}
                className="closebtn"
                style={{ float: 'right' }}
              >
                <img
                  className="close-img"
                  src={ExitButton}
                  alt="close-button"
                />
              </button>
            </div>

            <div className="overlay-content row">
              <div className="hum-grid1 col-md-4 col-sm-12">
                <ul className="over-txt">
                  <li>
                    <Link
                      to="/travel-and-tourism"
                      onClick={this.toggleMenu}
                      className="hum-bottom"
                    >
                      TRAVEL & TOURISM
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/urban-development"
                      onClick={this.toggleMenu}
                      className="hum-bottom"
                    >
                      URBAN DEVELOPMENT
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/technology"
                      onClick={this.toggleMenu}
                      className="hum-bottom"
                    >
                      TECHNOLOGY
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/our-team"
                      onClick={this.toggleMenu}
                      className="hum-bottom"
                    >
                      OUR TEAM
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      onClick={this.toggleMenu}
                      className="hum-bottom"
                    >
                      CONTACT US
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Header
